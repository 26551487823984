$primary: #86c44d;

@import 'node_modules/bootstrap/scss/bootstrap';

.app {
    min-height: 100vh;
}

.btn-primary {
    color: white !important;
}

.phone-link {
    font-size: 1.5rem;
}
