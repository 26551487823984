/**
 * Transition fade-out-in en React
 */
$duration: 500;

.enter,
.exit,
.appear {
    animation-duration: $duration * 1ms;
    animation-name: fade;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.exit {
    animation-direction: reverse;
}

@keyframes fade {
    0% {
        opacity: 0;
        position: absolute;
    }
    49.99% {
        opacity: 0;
        position: absolute;
    }
    50% {
        opacity: 0;
        position: inherit;
    }
    100% {
        opacity: 1;
        position: inherit;
    }
}

:export {
    duration: $duration;
}
